import React, { useState, useRef } from 'react';
import intro_video from "./videos/video.mp4";
import { IoVolumeMediumSharp } from "react-icons/io5";
import './style/style.css';


function Intro() {
  const [volume, setVolume] = useState(0.5); // Volume iniziale al 50%
  const videoRef = useRef(null);

  return (
    <>
      <div id='intro' className='intro-container'>
        <div className="video-container">
          
          <video
            ref={videoRef}
            className='video-intro'
            autoPlay
            loop
            controls
            muted
          >
            <source src={intro_video} type="video/mp4" />
            Il tuo browser non supporta il tag video.
          </video>
        </div>
      </div>
    </>
  );
}

export default Intro;
