import React, { useState, useEffect } from 'react';

const Counter = ({ start, end, duration }) => {
  const [count, setCount] = useState(start);
  
  useEffect(() => {
    const startTime = performance.now();
    
    const animate = (timestamp) => {
      const elapsedTime = timestamp - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const currentNumber = Math.floor(progress * (end - start) + start);
      setCount(currentNumber);
      
      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [start, end, duration]);

  return (
    <div className="counter">
      {count}+
    </div>
  );
};

export default Counter;
