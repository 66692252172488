import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './style/style.css'
import loreprofile from './img_profile/profile.gif';
import Counter from './CounterNumbers';
import { IoMailOutline } from "react-icons/io5";
import { BsInstagram } from "react-icons/bs";
import { FaSquareFacebook, FaTiktok   } from "react-icons/fa6";
import Contattami from './Contattami';
import ListinoPrezzi from './Eventi';
import Gallery from './Gallery';

function Home() {
  const [age, setAge] = useState(null);

  useEffect(() => {
    const calculateAge = () => {
      const birthDate = new Date('2001-03-16'); // Data di nascita statica
      const today = new Date();

      let calculatedAge = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        calculatedAge--;
      }

      setAge(calculatedAge);
    };

    calculateAge();
  }, []);

  return (
    <>
        <div id="chi-sono" className='profile-container'>
          <div  className='name-sub-container'>
            Lorenzo Mameli
            <div className='subtitle-roles'>
              Lorenzo Mameli vive ad Assemini (CA), ha {age} anni ed è un prestigiatore brillante.
              <br /> 
              Ha iniziato il suo percorso con
              lo street - magic cioè l'arte di fare magia con i passanti per strada.
              <br /> 
              Col passare degli anni si è specializzato nell'esibirsi nelle piazze con grandi e piccoli, feste di compleanno 
              matrimoni e grandi palchi, arrivando con la sua magia comica in programmi televisivi, tra cui il talent "RIFLETTORI" come finalista. Partecipando come ospite speciale nelle interviste radiofoniche, portando il suo spettacolo anche in alcuni resort molto importanti nel panorama sardo tra cui: Limone Beach Resort, is Morus, Alma Resort ecc... le sue esibizioni ricche di illusioni spettacolari, hanno affascinato non solo gli spettatori, ma anche gli influencer e cantanti famosi.
            </div>
            <a href='#contattami' className='call-btn'>CONTATTAMI</a>
            <a href='tel:+393801864030' name='contact' className='call-btn'>CHIAMAMI SUBITO</a>
            <div className='statistics-container'>
                <div className='statistics'>
                  <Counter start={0} end={300} duration={1000}/>
                  <div className='event-subtitles'>
                    EVENTI REALIZZATI
                  </div>
                </div>

                <div className='statistics'>
                  <Counter start={0} end={6} duration={1000}/>
                  <div className='event-subtitles'>
                    ANNI DI ESPERIENZA
                  </div>
                </div>
            </div>
            <div className='subtitle-social'>
              Seguimi sui social
            </div>
            <a href='https://www.instagram.com/lorenzomameli301?igsh=MWFudjFtd2h2eGhvbA==' target="_blank"><BsInstagram className='social-icon-middle'/></a>  
            <a href='https://www.tiktok.com/@lorenzomameli0' target="_blank"><FaTiktok className='social-icon-middle'/></a>
            <a href='https://www.facebook.com/share/6RjLWF32FLQUeyoU/?mibextid=LQQJ4d' target="_blank"><FaSquareFacebook className='social-icon-middle'/></a>
            
          </div>
          <div className='img-profile-container'>
            <img src={loreprofile} className='img-profile'/>
          </div>
            
          
          <div className='socials-vertical'>
            Contattami 
            <a href='mailto:example@example.com?subject=Hello%20World&'><IoMailOutline  className='rotate90'/></a>
            - Seguimi sui social - 
            <a href='https://www.instagram.com/lorenzomameli301?igsh=MWFudjFtd2h2eGhvbA==' target="_blank"><BsInstagram className='rotate90'/></a>  
            <a href='https://www.tiktok.com/@lorenzomameli0' target="_blank"><FaTiktok className='rotate90'/></a>
            <a href='https://www.facebook.com/share/6RjLWF32FLQUeyoU/?mibextid=LQQJ4d' target="_blank"><FaSquareFacebook className='rotate90'/></a> 
          </div>
        </div>

        <ListinoPrezzi />
        <Contattami />
        <Gallery />
    </>
  );
}

export default Home;