import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './App.css';
import './style/style.css';
import Counter from './CounterNumbers';

function Contattami() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // Previene il ricaricamento della pagina

    emailjs.sendForm(
      'service_jzzpmlf',      // Sostituisci con il tuo Service ID
      'template_lucamwe',     // Sostituisci con il tuo Template ID
      form.current,
      'JonQrm7Vq76BaOdzl'          // Sostituisci con il tuo User ID
    )
    .then((result) => {
        console.log('Email inviata con successo!', result.text);
        alert('Email inviata con successo!');
        e.target.reset(); // Ripristina il form dopo l'invio
    }, (error) => {
        console.log('Errore nell\'invio dell\'email:', error.text);
        alert('Errore nell\'invio dell\'email. Riprova più tardi.');
    });
  };

  return (
    <>
    <div id='contattami' className='contact-container'>
      <form ref={form} onSubmit={sendEmail}>
        <div className='contact-inputs'>
          <div className='contact-title'>
            CONTATTAMI
          </div>
          <input type='text' name='name' placeholder='Nome' required />
          <input type='email' name='email' placeholder='Email' required />
          <textarea name='description' placeholder='Richiesta...' className='textarea-description' required></textarea>
          <button type='submit' name='send' className='send-btn'>INVIA</button>
        </div>
      </form>

      <div className='other-title'>
        OPPURE
      </div>

      <div className='call-title'>
        <a href='tel:+393801864030' name='contact' className='call-btn'>CHIAMAMI SUBITO</a>
      </div>
      
    </div>
    </>
    

  );
}

export default Contattami;
