import './App.css';
import './style/style.css';
import Counter from './CounterNumbers';
import { React, useState } from 'react';

// Funzione per richiamare tutte le immagini da una cartella
function importAll(r) {
    return r.keys().map(r);
}

const images_eventi = importAll(require.context('./gallery/eventi-interviste', false, /\.(png|jpe?g|svg)$/));
const images_bambini = importAll(require.context('./gallery/spettacolibambini', false, /\.(png|jpe?g|svg)$/));
const images_all = importAll(require.context('./gallery/images', false, /\.(png|jpe?g|svg)$/));

function Gallery() {
    let width = 250;
    let height = 250;

    const [filterAllActive, setFilterAllActive] = useState(true);
    const [filter1Active, setFilter1Active] = useState(false);
    const [filter2Active, setFilter2Active] = useState(false);

    const filterActive = (num)=>{
        setFilterAllActive(false);
        setFilter1Active(false);
        setFilter2Active(false);

        switch(num){
            case 0:
                setFilterAllActive(true);
                break;
            case 1:
                setFilter1Active(true);
                break;
            case 2:
                setFilter2Active(true);
                break;
        }
    }

    return (
        // galleria fotografica
        <div id='galleria' className='gallery-container'>
            <div className='gallery-title'>
                Galleria
            </div>
            <div className='gallery-subtitle'>
                <a
                    href='#'
                    className={filterAllActive ? 'gallery-subtitle-active' : ''}
                    onClick={()=>filterActive(0)}
                >
                    Tutti   
                </a>

                <a 
                    href='#'
                    className={filter1Active ? 'gallery-subtitle-active' : ''}
                    onClick={()=>filterActive(1)}
                >
                    Grandi Eventi / Interviste    
                </a>

                <a
                    href='#'
                    className={filter2Active ? 'gallery-subtitle-active' : ''}
                    onClick={()=>filterActive(2)}
                >
                    Spettacoli per bambini   
                </a>
            </div>
            <div className='gallery-photos'>
                <div className='gallery-photo' >
                    {images_eventi.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`img-${index}`}
                        style={{ width: width, height: height, margin: '10px' }} // Dimensioni predefinite
                        hidden={filterAllActive ? false : filter1Active ? false : true}
                    />
                ))}
                    {images_bambini.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`img-${index}`}
                        style={{ width: width, height: height, margin: '10px' }} // Dimensioni predefinite
                        hidden={filterAllActive ? false : filter2Active ? false : true}
                    />
                ))}
                {images_all.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`img-${index}`}
                        style={{ width: width, height: height, margin: '10px' }} // Dimensioni predefinite
                        hidden={filterAllActive ? false : true}
                    />
                ))}
                </div>
                </div>
        </div>
            
        
    );
}

export default Gallery;