import logo from './logo.png';
import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import Intro from './Intro';
import Footer from './Footer';

function App() {
  return (
    <>
      <Navbar />
      <Intro />
      <Home />
      <Footer />
    </>
  );
}

export default App;
