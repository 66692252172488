import logo from './logo.svg';
import './App.css';

function Navbar() {
  return (
        <div className='navbar'>
            <div className='navbar-title'><a href='#intro'>Lorenzo Mameli</a></div>
            <div className='navbar-list'>
                <ul>
                    <a href='#chi-sono'><li>Chi Sono</li></a>
                    <a href='#eventi'><li>Eventi</li></a>
                    <a href='#contattami'><li>Contattami</li></a>
                    <a href='#galleria'><li>Galleria</li></a>
                </ul>
            </div>
        </div>
  );
}

export default Navbar;