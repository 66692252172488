import logo from './logo.svg';
import './App.css';
import './style/style_listino.css';

function Eventi() {
  return (
    <>
    <div id='eventi' className='event-container'>
        <div  className='listino-container'>
            <div className='listino-title'>Spettacoli per bambini</div>
            <div className='listino-table'>
                Adatto ai bambini dai 5 anni in su, la durata dello spettacolo 
                sarebbe di 40/45 minuti. Lo spettacolo è molto variegato con carte, 
                animaletti, cubi da cui escono oggetti, palline di spugna, carte giganti e
                tanto altro.
                <br />
                <div className='title-attrezzatura'>
                    Attrezzatura
                </div>
                <ul>
                    <li>Tavolo personale</li>
                    <li>Cassa con microfono</li>
                    <li>Giochi di prestigio</li>
                </ul>
            </div>
            <a href='#contattami' className='contacts-btn'>Contattami</a>
        </div>

        <div className='listino-container'>
            <div className='listino-title'>Spettacoli per adulti</div>
            <div className='listino-table'>
                Adatto per diverse occasioni, tra cui feste private, matrimoni,
                feste paesane ecc...
                La durata può andare dai 30 minuti fino a 1 ora e 30 minuti, mischiando
                close-up, mentalismo, magia comica e tanto altro.
                <br />
                <div className='title-attrezzatura'>
                    Attrezzatura
                </div>
                <ul>
                    <li>Tavolo personale</li>
                    <li>Cassa con microfono</li>
                    <li>Giochi di prestigio</li>
                </ul>
            </div>
            <a href='#contattami' className='contacts-btn'>Contattami</a>
        </div>

        <div className='listino-container'>
            <div className='listino-title'>Grandi palchi</div>
            <div className='listino-table'>
                Adatto a diverse tipologie di palchi, da quelli del teatro fino a quelli
                di piazza, per un pubblico misto di grandi e piccoli. La durata
                è flessibile in base all'evento.
            </div>
            <a href='#contattami' className='contacts-btn'>Contattami</a>
        </div>
    </div>
    </>
  );
}

export default Eventi;